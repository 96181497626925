<template>
  <b-container>
    <b-overlay rounded="sm" :show="show">
      <b-row class="mb-2 justify-content-end">
        <b-col md="8" lg="6">
          <div class="input-group">
            <input type="text" class="form-control" placeholder="Introduzca busqueda..." v-model="search">
            <div class="input-group-append">
              <button class="btn btn-success" @click="searchTour()" type="button"><feather-icon
                  icon="SearchIcon" /></button>
              <button class="btn btn-primary" @click="searchReset()" type="button"><feather-icon
                  icon="XIcon" /></button>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6" v-for="item, index in list" :key="index">
          <b-card no-body class="overflow-hidden">
            <b-card-header class="bg-info">
              <b-card-title class="text-white">
                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                  stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                  <circle cx="12" cy="8" r="7"></circle>
                  <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline>
                </svg>           
                Ranking - {{ item.name }}{{ item.weekdays !== 0 ? ' - ' : '' }} {{ weekdays[item.weekdays].text }}
              </b-card-title>

            </b-card-header>
            <a :href="'/player-rankings/tournament-ranking/' + item.id">
              <b-card-body>
                <div class="mt-2">
                  <span class="mr-2"><strong>Fecha :</strong> {{ formatDate(item.tournament_date) }}
                    <strong>Hora:</strong>
                    {{ item.tournament_hour ? formattedTime(item.tournament_hour) : '' }} </span>
                  <b-card-text class="mb-25"><strong>Nivel: </strong>{{ item.level }} <strong> - Número pistas:</strong>
                    {{ item.number_courts }}<br></b-card-text>                
                
                </div>
              </b-card-body>
            </a>

          </b-card>
        </b-col>
        <div class="text-center">
          <!-- <h5 v-if="list.length == 0" class="text-danger h4 text-center">¡NO HAY TORNEOS!</h5> -->
        </div>


      </b-row>
    </b-overlay>
    <b-modal id="insert-result-modal" title="Introducir resultados de partido" class="custom-modal" no-close-on-backdrop
      modal-class="margin-top">
      <span v-for="item in couples">
        {{ item.title }}
      </span>
      <form ref="formRegister">
        <input type="hidden" name="tournament_id" :value="this.tournament_id">
        <input type="hidden" name="player_id" :value="user.player.id">
        <b-row>
          <b-col md="6">
            <!-- {{ couples }} -->
            <v-select v-if="couples.length > 0" class="mb-2" :options="couples" placeholder="Seleccione pareja ganadora"
              label="title" :getOptionKey="getOptionKey" v-model="winner_couple" name="couple1" />
          </b-col>
          <b-col md="6">
            <v-select :options="couples" placeholder="Seleccione pareja perdedora" label="title"
              :getOptionKey="getOptionKey" v-model="lose_couple" name="couple2" />
            <b-row>
              <b-col md="6">
                <input type="text" name="result" placeholder="Resultado ej: 5-8" class="form-control mt-2">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </form>
      <template #modal-footer="{ cancel }">
        <b-row class="w-100">
          <b-col md="5">
            <button class="btn btn-success btn-sm btn-block mb-1" @click="insertResultMatch">Enviar resultados</button>
          </b-col>
          <b-col md="4">
            <button class="btn btn-secondary btn-sm btn-block" @click="cancel()">Cancelar</button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </b-container>

</template>
<script>
import { VBModal, BForm, BFormInput, BFormTextarea, BContainer, BRow, BCardHeader, BCard, BCardBody, BCardText, BButton, BCardTitle, BCol, BOverlay } from 'bootstrap-vue'
import { mapState } from 'vuex';
import moment from 'moment';
import vSelect from 'vue-select'
export default {
  props: ['type'],
  watch: {
    type: function () {
      this.show = true;
      this.search = '',
      this.list = [];    
        
      
    }
  },
  components: { vSelect, VBModal, BForm, BFormInput, BFormTextarea, BContainer, BCardHeader, BCard, BRow, BCardBody, BCardText, BButton, BCardTitle, BCol, BOverlay },
  data() {
    return {
      show: false,
      list: [],
      tournament_id: null,
      winner_couple: null,
      lose_couple: null,
      couples: [],
      search: '',
      weekdays: [
        {text: ''},
        {text: 'Lunes'},
        {text: 'Martes'},
        {text: 'Miércoles'},
        {text: 'Jueves'},
        {text: 'Viernes'},
        {text: 'Sábado'},
        {text: 'Domingo'},
      ]
    }
  },
  created() {
    this.show = true;
    this.$http.get("/api/getPlayerTournamentsRanking?id=" + this.user.player.id).then((response) => {
      // console.log(response.data)
      this.list = response.data;
      console.log(this.list);
      this.show = false;
    });



  },
  methods: {
    openInsertResultModal(tournament) {
      this.tournament_id = tournament;

      // recupera parejas de partido
      this.$http.get("/api/getCouplesMatch?id=" + this.tournament_id).then((response) => {
        this.couples = response.data;
        this.show = false;
      });

      this.$bvModal.show('insert-result-modal');

    },
    getOptionKey(option) {
      return option ? option.id : null;
    },
    insertResultMatch() {

    },
    searchTour() {
      this.show = true;
      this.$http.get("/api/getPlayerTournaments?search=" + this.search).then((response) => {
        this.list = response.data;
        this.show = false;
      });
    },
    searchReset() {
      this.search = '';
      this.searchTour();
    },


    formattedTime(hour) {
      // Usando Moment.js para dar formato a la fecha
      return moment(hour, 'HH:mm').format('HH:mm'); // Formato de 12 horas con AM/PM
    },
    formatDate(date) {
      if (!date) return '';
      return moment(date).format('DD-MM-YYYY');
    },
  }, computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  }
}
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-select.scss';

.notification-rounded {
  display: inline-block;
  width: 25px;
  height: 25px;
  color: white;
  padding: 5px;
  padding-top: 3px;
  border-radius: 25px;
  text-align: center;
}

.green {
  background-color: green;
}

.grey {
  background-color: grey;
}

.is_recurring {
  background-color: rgb(233, 196, 31);
  color: black;
  padding: 0px 2px;
}

.bg-no-conf {
  background-color: rgb(231, 90, 109);
  padding: 2px;
  color: white;
}

.bg-yes-conf {
  background-color: rgb(90, 231, 114);
  padding: 2px 4px;
  color: rgb(0, 0, 0);
}

#insert-result-modal {
  margin-top: 40px;
}
</style>